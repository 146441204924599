import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";
import Rails from "@rails/ujs"; // Importa o Rails UJS

export default class extends Controller {
  static targets = ["canvas"];

  connect() {
    if (this.canvasTarget) {
      console.log("Assinatura controller loaded");

      // Inicializa o SignaturePad com o background branco
      this.signaturePad = new SignaturePad(this.canvasTarget, {
        backgroundColor: 'rgb(255, 255, 255)',
        penColor: "rgb(66, 133, 244)",
        velocityFilterWeight: 0.0, // Desabilita o filtro de velocidade
        throttle: 0, // Desabilita o throttling
        minWidth: 0.7,
        maxWidth: 1.0,
        minDistance: 1 // Reduz o intervalo mínimo de distância entre pontos


      });

      // Redimensiona o canvas para garantir uma visualização nítida
      this.resizeCanvas();

      // Adiciona um listener para redimensionar o canvas quando a janela é redimensionada
      window.addEventListener("resize", this.resizeCanvas.bind(this));

      // Adiciona um listener para o evento 'shown.bs.modal' para garantir que o canvas
      // seja redimensionado apenas quando o modal estiver visível.
      const modal = this.canvasTarget.closest(".modal");
      if (modal) {
        $(modal).on("shown.bs.modal", this.resizeCanvas.bind(this));
      }
    }
  }

  disconnect() {
    // Remove o listener quando o controller é desconectado
    window.removeEventListener("resize", this.resizeCanvas.bind(this));

    // Remove o listener do evento 'shown.bs.modal' se o modal for desconectado
    const modal = this.canvasTarget.closest(".modal");
    if (modal) {
      $(modal).off("shown.bs.modal", this.resizeCanvas.bind(this));
    }
  }

  resizeCanvas() {
    const canvas = this.canvasTarget;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    // Ajusta as dimensões do canvas conforme o tamanho do contêiner e a densidade de pixels
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;

    // Ajusta a escala do contexto para manter a proporção correta dos desenhos
    canvas.getContext("2d").scale(ratio, ratio);

    // Sincroniza o estado interno do SignaturePad com o visual do canvas
    if (this.signaturePad) {
      // Restaura o desenho após o redimensionamento
      this.signaturePad.fromData(this.signaturePad.toData());
    }
  }

  clear() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
  }

  save() {
    if (this.signaturePad && !this.signaturePad.isEmpty()) {
      const dataURL = this.signaturePad.toDataURL();
      console.log("Assinatura salva como data URL:", dataURL);
    } else {
      alert("Por favor, faça uma assinatura primeiro.");
    }
  }

  submitForm(event) {
    if (this.signaturePad && !this.signaturePad.isEmpty()) {
      const dataURL = this.signaturePad.toDataURL();

      // Colocar o dataURL no campo oculto do formulário
      document.getElementById('assinatura_data').value = dataURL;

      // Submeter o formulário via Rails UJS
      const form = document.getElementById('form-assinatura');
      Rails.fire(form, 'submit'); // Usa o Rails UJS para submeter o formulário

      // Fechar o modal após submeter
      const modal = this.canvasTarget.closest(".modal");
      if (modal) {
        $(modal).modal('hide');
      }
    } else {
      alert("Por favor, faça uma assinatura primeiro.");
    }
  }
}
